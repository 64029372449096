<template>
  <div class="project-information">
    <!-- 查询form -->
    <base-form
      :componentList="form"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        ></base-button>
      </div>
    </base-form>
    <div class="main-page-table__header">
      <base-button label="新增" @click="addInfo" icon="el-icon-plus" />
    </div>
    <!-- 表单 -->
    <base-table
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :dataSource.sync="tableData"
      :webPage="false"
      class="main-page-table with-add"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          @click="contractDetail(scope.row)"
          content="查看"
          icon="iconfont iconchakan"
        />
        <icon-button
          @click="updataInfo(scope.row)"
          v-if="scope.row.dataGetFlag === 0"
          content="修改"
          icon="iconfont iconbianji3"
        />
        <icon-button
          @click="delInfo(scope.row.keyId)"
          v-if="scope.row.dataGetFlag === 0 && scope.row.isDelete"
          content="删除"
          icon="iconfont iconshanchu1"
        />
      </template>
    </base-table>
    <!-- 新增修改弹出框 -->
    <add-contract
      :visible.sync="visible"
      :submitType="submitType"
      :keyId="keyId"
      :contractId="contractId"
      :dropOptions="dropOptions"
      @handleFilter='handleFilter'
    />
    <!-- 详情弹出框  -->
    <contract-details
      :visible.sync="visibleDetail"
      :name="name"
      :contractId="contractId"
      :keyId="keyId"
    />
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseForm from '@/components/common/base-form//base-form'
import BaseTable from '@/components/common/table/base-table/base-table'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { assetcontractApi } from '@/api/assetApi'
import { supplierApi } from '@/api/companyApi'
import ContractDetails from './components/contract-details'
import AddContract from './components/add-contract'
import { formConfig, columnsConfig } from './utils/config'

export default {
  name: 'projectInformation',
  components: {
    BaseForm,
    BaseTable,
    BaseButton,
    IconButton,
    ContractDetails,
    AddContract
  },
  data () {
    return {
      visible: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      tableData: [],
      loadCount: 0,
      name: '',
      visibleDetail: false,
      detailInfo: {},
      dropOptions: [],
      count: 0,
      fold: false,
      keyId: '',
      submitType: '',
      contractId: ''
    }
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    form () {
      return formConfig(this)
    },
    api () {
      return assetcontractApi
    }
  },
  methods: {
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.dropOptions = res.data
        }
      })
    },
    // 新增
    addInfo () {
      this.gysList()
      this.submitType = 'add'
      this.visible = true
    },
    // 修改信息
    updataInfo (data) {
      this.submitType = 'updata'
      this.keyId = data.keyId
      this.contractId = data.contractId
      this.visible = true
    },
    // 查看项目合同信息详情
    contractDetail (row) {
      this.name = '合同名称：' + row.contractName
      this.keyId = row.keyId
      this.contractId = row.contractId
      this.visibleDetail = true
    },
    // 重置
    clearParams () {
      const { pageSize, pageIndex } = this.queryParas
      this.queryParas = {
        pageSize, pageIndex
      }
    },
    // 查询
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },

    // 删除
    delInfo (keyId) {
      this.$stateConfirm({
        title: '提示',
        message: '确认删除该项目合同吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        assetcontractApi.delInfo({ keyId: keyId }).then((res) => {
          if (res.code === '0') {
            this.success('删除成功')
            this.handleFilter()
          } else {
            this.error('删除失败')
          }
        })
      })
    }
  },
  mounted () {
    this.gysList()
    this.handleFilter()
  }
}
</script>
<style lang="scss" scoped>

.order-table {
  margin: 0;
  padding-top: 0;
}
.project-information {
  height: calc(100% - 60px);
}
.el-dialog__body {
  padding: 0 20px !important;
}
</style>
