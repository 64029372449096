import BaseSelect from '@/components/common/base-select/base-select'
// 查询
export const formConfig = (content) => {
  return [
    {
      label: '项目编号',
      prop: 'projectCode',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入'

      }

    },
    {
      label: '项目名称',
      prop: 'projectName',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '合同名称',
      prop: 'contractName',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入'
      },
      isHidden: content.fold
    },
    {
      label: '供应商名称',
      prop: 'gysId',
      attrs: {
        placeholder: '请选择',
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true
      },
      tag: BaseSelect,
      isHidden: content.fold
    }
  ]
}

// 表格
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '供应商',
      prop: 'gysName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '区域',
      prop: 'area',
      'show-overflow-tooltip': true
    },
    {
      label: '项目公司名称',
      prop: 'xmgsName',
      'show-overflow-tooltip': true,
      align: 'left',
      minWidth: '120px'
    },
    {
      label: '项目编号',
      prop: 'projectCode',
      'show-overflow-tooltip': true
    },
    {
      label: '项目名称',
      prop: 'projectName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      'show-overflow-tooltip': true
    },
    {
      label: '合同名称',
      prop: 'contractName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '合同类别',
      prop: 'contractType',
      'show-overflow-tooltip': true
    },
    {
      label: '已付金额(元)',
      prop: 'paidAmount',
      'show-overflow-tooltip': true,
      minWidth: '110px',
      align: 'right',
      formatter: row => {
        return (row.paidAmount ? row.paidAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0')
      }
    },
    {
      label: '所属城市公司',
      prop: 'city',
      'show-overflow-tooltip': true,
      minWidth: '120px'
    },
    {
      label: '是否已融资放款',
      minWidth: '120px',
      prop: 'repayStatus',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.repayStatus === 0 ? '未放款' : '已放款')
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
